import React, { Component } from 'react'
import axios from 'axios';
export class Posts extends Component {
    state = {
        posts: [],
        isLoaded: false
    }

    componentDidMount () {
        axios.get('https://apitest.techtalksbynoah.com/wp-json/wp/v2/posts/')
            .then(res => this.setState({
                posts: res.data,
                isLoaded: true
            }))
            .catch(err => console.log(err))
    }

    render() {
        const {posts, isLoaded} = this.state;
        return (
            <div id="postContainer">
                {posts.map(post =>
                    <div id="postItem">
                        <div id="featuredImage">

                        </div>
                        <div id="postMeta">
                            <h4>{post.title.rendered}</h4>
                            <p dangerouslySetInnerHTML={{__html: post.excerpt.rendered}}></p>
                        </div>
                    </div>
                )}
            </div>
        )
    }
    
}
export default Posts