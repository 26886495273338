import './App.css';
import Posts from './components/Posts'

function App() {
  return (
    <div className="App">
      <Posts />
    </div>
  );
}

export default App;
